import React, { createContext, useContext, useRef, useEffect, useState } from 'react';

const TimerContext = createContext();

export const useTimer = () => useContext(TimerContext);

export const TimerProvider = ({ children }) => {
  const [mode, setMode] = useState('pomodoro');
  const [timer, setTimer] = useState({ remainingSeconds: 25 * 60 });
  const [timerActive, setTimerActive] = useState(false);
  let startTimerTime = useRef(null);
  let stopTimerTime = useRef(null);

  // Store external tick callbacks
  const tickCallbacks = useRef(new Set());
  // Store mode change callbacks
  const modeChangeCallbacks = useRef(new Set()); // Added line

  // Store the web worker reference
  const workerRef = useRef(null);

  useEffect(() => {
    if (workerRef.current === null) {
      workerRef.current = new Worker('timerWorker.js');
    }

    workerRef.current.onmessage = (e) => {
      if (e.data === 'tick') {
        if (startTimerTime.current === null) {
          startTimerTime.current = Date.now();
        }

        setTimer(() => {
          const currentTime = Date.now();
          const elapsedSeconds = Math.floor((currentTime - startTimerTime.current) / 1000);
          const totalSeconds = getMinutesForMode(mode) * 60;
          return { remainingSeconds: totalSeconds - elapsedSeconds };
        });
        // TODO: figure how to invoke the tick callback in useEffects to make sure it runs AFTER the timer is set async
        // Trigger callbacks in UI components that react to the ticker message
        tickCallbacks.current.forEach((callback) => callback());
      }
    };
  }, [mode]);

  useEffect(() => {
    // Trigger mode change callbacks after timer state is updated
    // Running it in useEffect to ensure it runs after timer is updated
    modeChangeCallbacks.current.forEach((callback) => callback(mode));
  }, [timer]);

  const registerTickCallback = (callback) => {
    tickCallbacks.current.add(callback);
  };

  const unregisterTickCallback = (callback) => {
    tickCallbacks.current.delete(callback);
  };

  // Register mode change callback
  const registerModeChangeCallback = (callback) => {
    modeChangeCallbacks.current.add(callback); // Added line
  };

  // Unregister mode change callback
  const unregisterModeChangeCallback = (callback) => {
    modeChangeCallbacks.current.delete(callback); // Added line
  };

  const startTimer = () => {
    if (startTimerTime.current === null) {
      startTimerTime.current = Date.now();
    }

    if (stopTimerTime.current !== null) {
      // Adjust startTimerTime by the paused duration
      startTimerTime.current += Date.now() - stopTimerTime.current;
      stopTimerTime.current = null; // Reset stopTime after adjusting
    }

    setTimerActive(true);
    workerRef.current.postMessage('start');
  };

  const pauseTimer = () => {
    stopTimerTime.current = Date.now();
    setTimerActive(false);
    workerRef.current.postMessage('stop');
  };

  // TODO: currently this resets the timer to 0 - the logic from pomodoro.js should be moved into this file
  const switchMode = (newMode) => {
    setTimerActive(false);
    workerRef.current.postMessage('stop');
    setMode(newMode);
    const newMinutes = getMinutesForMode(newMode);
    setTimer({ remainingSeconds: newMinutes * 60 });
    stopTimerTime.current = null;
    startTimerTime.current = null;
  };

  const resetTimer = () => {
    setTimerActive(false);
    workerRef.current.postMessage('stop');
    const newMinutes = getMinutesForMode(mode);
    setTimer({ remainingSeconds: newMinutes * 60 });
    stopTimerTime.current = null;
    startTimerTime.current = null;
  };

  const getMinutesForMode = (mode) => {
    switch (mode) {
      case 'pomodoro':
        return 25;
      case 'short-break':
        return 5;
      case 'long-break':
        return 15;
      default:
        return 25;
    }
  };

  // Function to get remaining seconds
  const getRemainingSeconds = () => timer.remainingSeconds % 60;

  // Function to get remaining minutes
  const getRemainingMinutes = () => Math.floor(timer.remainingSeconds / 60);

  return (
    <TimerContext.Provider
      value={{
        mode,
        timer,
        startTimer,
        pauseTimer,
        resetTimer,
        timerActive,
        switchMode,
        getRemainingSeconds,
        getRemainingMinutes,
        registerTickCallback,
        unregisterTickCallback,
        registerModeChangeCallback,
        unregisterModeChangeCallback,
      }}
    >
      {children}
    </TimerContext.Provider>
  );
};
