// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/PomodoroGraph.css */
.graph-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10px, 1fr));
  gap: 2px;
}

.day {
  width: 10px;
  height: 10px;
  border-radius: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/components/PomodoroGraph.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;EACE,aAAa;EACb,2DAA2D;EAC3D,QAAQ;AACV;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":["/* src/PomodoroGraph.css */\n.graph-container {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(10px, 1fr));\n  gap: 2px;\n}\n\n.day {\n  width: 10px;\n  height: 10px;\n  border-radius: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
