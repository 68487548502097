import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import Feed from './Feed';
import { useTimer } from './contexts/TimerContext';
import { formatWithLeadingZero } from './utils/formatter';
import PomodoroGraph from './components/PomodoroGraph';
import { CheckIcon } from '@heroicons/react/20/solid';

export default function Pomodorino() {
  // Check local storage for finished pomodoros
  const storedPomodoros = localStorage.getItem('finishedPomodoros');
  const [finishedPomodoros, setFinishedPomodoros] = useState(
    storedPomodoros ? JSON.parse(storedPomodoros) : [],
  );

  // Timer context variables and functions to control the timer
  const {
    timer,
    mode,
    startTimer,
    pauseTimer,
    resetTimer,
    timerActive,
    switchMode,
    getRemainingSeconds,
    getRemainingMinutes,
    registerTickCallback,
    unregisterTickCallback,
    registerModeChangeCallback,
    unregisterModeChangeCallback,
  } = useTimer();

  // Local variables to store the timer state used in the UI component
  const [minutes, setMinutes] = useState(25);
  const [seconds, setSeconds] = useState(0);
  const [finishedIntervals, setFinishedIntervals] = useState(0);
  const [currentPomodoro, setCurrentPomodoro] = useState(1);
  const [todaysPomodoros, setTodaysPomodoros] = useState(() => {
    const storedPomodoros = localStorage.getItem('finishedPomodoros');
    if (storedPomodoros !== null) {
      const parsedPomodors = JSON.parse(storedPomodoros);
      const today = new Date().setHours(0, 0, 0, 0);
      const filteredTodaysPomodoros = parsedPomodors.filter(
        (p) => new Date(p).setHours(0, 0, 0, 0) === today,
      );
      return filteredTodaysPomodoros ? filteredTodaysPomodoros : [];
    } else {
      return [];
    }
  });
  const bellSound = new Audio('./sounds/ding.wav');
  const dailyGoal = 4; // Variable for daily goal

  const tickFromTimer = () => {
    //TODO: move some of this logic into the timer - strange to keep it here
    if (timerActive) {
      if (timer.remainingSeconds > 0) {
        // Convert total remaining seconds to minutes and seconds
        setSeconds(getRemainingSeconds());
        setMinutes(getRemainingMinutes());
      } else {
        setFinishedIntervals(finishedIntervals + 1);
        if (mode === 'pomodoro') {
          //TODO: improve this storage
          const newPomodoro = [...finishedPomodoros, new Date()];
          setFinishedPomodoros(newPomodoro);
          localStorage.setItem('finishedPomodoros', JSON.stringify(newPomodoro)); // Update local storage
          setCurrentPomodoro(currentPomodoro + 1);
          switchMode('short-break');

          // Update today's pomodoros in the state
          const today = new Date().setHours(0, 0, 0, 0);
          const updatedTodaysPomodoros = newPomodoro.filter(
            (p) => new Date(p).setHours(0, 0, 0, 0) === today,
          );
          setTodaysPomodoros(updatedTodaysPomodoros);
          localStorage.setItem('todaysPomodoros', JSON.stringify(updatedTodaysPomodoros)); // Update local storage
        } else {
          switchMode('pomodoro');
        }
        resetTimer();
        bellSound.play();
      }
    }

    updateTabTitle();
  };

  const modeChangeFromTimer = () => {
    const totalSeconds = timer.remainingSeconds;
    setSeconds(totalSeconds % 60);
    setMinutes(Math.floor(totalSeconds / 60));
    updateTabTitle();
  };

  const toggleTimer = () => {
    if (!timerActive) {
      startTimer();
      // Track the event in Google Analytics
      ReactGA.event({
        category: 'Timer',
        action: 'Started Timer',
        label: mode,
      });
    } else {
      pauseTimer();
    }
  };

  const updateTabTitle = () => {
    // Set document title to show remaining / elapsed time
    document.title = `${getRemainingMinutes()}:${getRemainingSeconds()} - Pomodoro Tracker`;
  };

  // New function to clear history
  const clearHistory = () => {
    setFinishedPomodoros([]);
    localStorage.removeItem('finishedPomodoros'); // Clear local storage
  };

  const getPomodoroStreakBoxes = () => {
    const boxes = [];

    for (let i = 0; i < dailyGoal; i++) {
      boxes.push(
        <div
          key={i}
          className={`inline-block w-4 h-4 mx-1 ${
            i < todaysPomodoros.length ? 'bg-indigo-600' : 'bg-gray-300'
          }`}
        ></div>,
      );
    }

    return <div className='flex'>{boxes}</div>;
  };

  useEffect(() => {
    ReactGA.initialize('G-QQ885QN6QD');
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });

    registerTickCallback(tickFromTimer);
    registerModeChangeCallback(modeChangeFromTimer);

    return () => {
      // avoid memory leaks
      unregisterTickCallback(tickFromTimer);
      unregisterModeChangeCallback(modeChangeFromTimer);
    };
  }, [registerTickCallback, unregisterTickCallback]);

  return (
    <div className='h-screen flex items-start justify-center'>
      <div className='bg-white shadow-lg rounded-lg p-8 w-full'>
        <div className='flex justify-around mb-8'>
          <span className='isolate inline-flex rounded-md shadow-sm'>
            {/* Pomodoro Button */}
            <button
              type='button'
              className={`relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 ${
                mode === 'pomodoro' ? 'bg-slate-200 text-black' : 'bg-white'
              }`}
              onClick={() => switchMode('pomodoro')}
            >
              Pomodoro
            </button>
            {/* Short-Break Button */}
            <button
              type='button'
              className={`relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 ${
                mode === 'short-break' ? 'bg-slate-200 text-black' : 'bg-white'
              }`}
              onClick={() => switchMode('short-break')}
            >
              Short-Break
            </button>
            {/* Long-Break Button */}
            <button
              type='button'
              className={`relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 ${
                mode === 'long-break' ? 'bg-slate-200 text-black' : 'bg-white'
              }`}
              onClick={() => switchMode('long-break')}
            >
              Long-Break
            </button>
          </span>
        </div>
        <div className='text-center text-6xl mb-8'>
          {formatWithLeadingZero(minutes)}:{formatWithLeadingZero(seconds)}
        </div>
        <div className='flex justify-center'>
          <button
            type='button'
            className='rounded bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 py-2 px-4 mx-1'
            onClick={toggleTimer}
          >
            {timerActive ? 'Pause' : 'Start'}
          </button>
          <button
            type='button'
            className='rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 py-2 px-4 mx-1'
            onClick={resetTimer}
          >
            Reset
          </button>
          <button
            type='button'
            className='rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 py-2 px-4 mx-1'
            onClick={clearHistory}
          >
            Clear History
          </button>
        </div>
        <div className='flex justify-center mt-8'>
          {mode === 'pomodoro' && (
            <>
              <span className='mr-2 text-sm font-normal'>
                Daily Focus Goal ({dailyGoal} pomodoros)
              </span>
              {todaysPomodoros.length < dailyGoal ? (
                getPomodoroStreakBoxes()
              ) : (
                <span className='ml-2 text-green-500 flex items-center'>
                  <CheckIcon className='h-5 w-5 text-green-500 flex-shrink-0 sm:-ml-1'></CheckIcon>
                </span>
              )}
            </>
          )}
        </div>
        <div className='mt-8'>
          <Feed pomodoros={finishedPomodoros}></Feed>
        </div>
        <div className='mt-8'>
          <PomodoroGraph finishedPomodoros={finishedPomodoros} />
        </div>
      </div>
    </div>
  );
}
