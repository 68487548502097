import { React, createContext, useContext, useState } from 'react';

const GlobalEventContext = createContext();

export function useGlobalEvent() {
  return useContext(GlobalEventContext);
}

export function GlobalEventProvider({ children }) {
  const [events, setEvents] = useState([]);

  const triggerEvent = (event) => {
    setEvents((prevEvents) => [...prevEvents, event]);
  };

  return (
    <GlobalEventContext.Provider value={{ events, triggerEvent }}>
      {children}
    </GlobalEventContext.Provider>
  );
}
