import React from 'react';
import './PomodoroGraph.css'; // Ensure this CSS file is set up to handle any non-Tailwind styling

const PomodoroGraph = ({ finishedPomodoros }) => {
  const generateGraphData = () => {
    const today = new Date();
    const lastYear = new Date(new Date().setFullYear(today.getFullYear() - 1));
    let dates = {};

    for (let d = lastYear; d <= today; d.setDate(d.getDate() + 1)) {
      const dateKey = d.toISOString().split('T')[0];
      dates[dateKey] = 0;
    }

    finishedPomodoros.forEach((pomodoro) => {
      const dateKey = new Date(pomodoro).toISOString().split('T')[0];
      if (dates[dateKey] !== undefined) {
        dates[dateKey] += 1;
      }
    });

    return dates;
  };

  const graphData = generateGraphData();

  const getColor = (count) => {
    if (count > 5) return 'bg-green-700';
    if (count > 3) return 'bg-green-500';
    if (count > 1) return 'bg-green-300';
    if (count > 0) return 'bg-green-100';
    return 'bg-gray-200';
  };

  const totalPomodoros = Object.values(graphData).reduce((acc, count) => acc + count, 0);
  const activityLabel =
    totalPomodoros > 0
      ? `${totalPomodoros} pomodoros in the last year`
      : 'No activity in the last year';

  return (
    <div>
      <div className='activity-label mb-4'>{activityLabel}</div>
      <div className='graph-container flex flex-wrap max-w-2xl'>
        {Object.entries(graphData).map(([date, count], index) => {
          const formattedDate = new Date(date).toLocaleDateString(undefined, {
            month: 'long',
            day: 'numeric',
          });
          const tooltipId = `tooltip-${index}`;
          return (
            <div key={date} className='relative'>
              <div
                onMouseEnter={() => {
                  document.getElementById(tooltipId).classList.remove('invisible', 'opacity-0');
                  document.getElementById(tooltipId).classList.add('visible', 'opacity-100');
                }}
                onMouseLeave={() => {
                  document.getElementById(tooltipId).classList.add('invisible', 'opacity-0');
                  document.getElementById(tooltipId).classList.remove('visible', 'opacity-100');
                }}
                className={`day ${getColor(count)} cursor-pointer`}
              ></div>
              <div
                id={tooltipId}
                role='tooltip'
                className='absolute z-10 invisible inline-block px-3 py-2 text-xs font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700 w-48'
              >
                {`${count} pomodoros on ${formattedDate}`}
                <div className='tooltip-arrow' data-popper-arrow></div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PomodoroGraph;
