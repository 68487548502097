import { React } from 'react';
import Dashboard from './Dashboard';
import { GlobalEventProvider } from './contexts/GlobalEventContext';

function App() {
  return (
    <GlobalEventProvider>
      <Dashboard />
    </GlobalEventProvider>
  );
}

export default App;
