/**
 * Formats a number as a string with a leading zero if it is less than 10.
 * @param {number} num - The number to format.
 * @return {string} The formatted number as a string.
 */
export function formatWithLeadingZero(num) {
  return String(num).padStart(2, '0');
}

/** Formats a date label for a post.  */
export function formatDateLabel() {
  const now = new Date();
  const day = now.getDate();
  const month = now.toLocaleString('default', { month: 'short' });

  const label = `${day} ${month}`;
  return label;
}

export function convertTimeStringIntoAFullDate(timeString) {
  const currentDate = new Date();
  const [hours, minutes] = timeString.split(':').map(Number);
  currentDate.setHours(hours, minutes, 0); // Set seconds to 0 for consistency
  return currentDate.toISOString();
}

export function formatTimeToHHMM(time) {
  return time
    ? new Date(time).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      })
    : '';
}
