import { React, Fragment, useState, useEffect, useRef } from 'react';
import { Listbox, Transition, Dialog } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { convertTimeStringIntoAFullDate, formatTimeToHHMM } from './utils/formatter';
import { useGlobalEvent } from './contexts/GlobalEventContext';

import {
  XMarkIcon,
  PlayIcon,
  TrashIcon,
  PencilIcon,
  TagIcon,
  FlagIcon,
  ClockIcon,
  CheckCircleIcon as CheckCircleIconOutlined,
} from '@heroicons/react/24/outline';

const labels = [
  { name: 'No-Label', value: null },
  { name: 'Errands', value: 'errands' },
  { name: 'Fun/Learning', value: 'learning' },
  { name: 'Planning', value: 'planning' },
  { name: 'Health/Fitness/Welness', value: 'health' },
  { name: 'Software Engineering', value: 'engineering' },
  { name: 'Product Design', value: 'design' },
  { name: 'Writing', value: 'writing' },
  { name: 'Strategy', value: 'strategy' },
  { name: 'Sales', value: 'sales' },
  { name: 'Brand/Marketing/Content', value: 'marketing' },
  { name: 'Communication', value: 'communication' },
  // More items...
];

// Add this array for priority options
//TODO: maybe use eisenhower matrix
const priorities = [
  { name: 'Priority 1', value: 1 },
  { name: 'Priority 2', value: 2 },
  { name: 'Priority 3', value: 3 },
  { name: 'Priority 4', value: 4 },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// Move to external file somewhere
function timeAgo(dateString) {
  const date = new Date(dateString);
  const now = new Date();
  const seconds = Math.round((now - date) / 1000);
  const minutes = Math.round(seconds / 60);
  const hours = Math.round(minutes / 60);
  const days = Math.round(hours / 24);
  const months = Math.round(days / 30);
  const years = Math.round(days / 365);

  if (seconds < 60) {
    return 'just now';
  } else if (minutes < 60) {
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else if (hours < 24) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (days < 30) {
    return `${days} day${days > 1 ? 's' : ''} ago`;
  } else if (months < 12) {
    return `${months} month${months > 1 ? 's' : ''} ago`;
  } else {
    return `${years} year${years > 1 ? 's' : ''} ago`;
  }
}

export default function Feed({ pomodoros }) {
  //const [selected, setSelected] = useState(moods[5]);
  const [showCreateTaskBox, setShowCreateTaskBox] = useState(false); // Add this line
  const [labelled, setLabelled] = useState(labels[0]);
  const [priority, setPriority] = useState(1);
  const [tasks, setTasks] = useState([]);
  const [taskStartTime, setTaskStartTime] = useState('');
  const [taskEndTime, setTaskEndTime] = useState('');
  const [editingTask, setEditingTask] = useState(null);
  const [editSideBarOpen, setEditSideBarOpen] = useState(false);
  const addTaskFormRef = useRef(null);
  const { events } = useGlobalEvent();

  // Ref for the title and desc input
  const createTaskTitleInputRef = useRef(null);
  const createTaskDescriptionInputRef = useRef(null);
  const editTaskTitleInputRef = useRef(null);
  const editDescriptionInputRef = useRef(null);

  // Convert pomodoros to a format compatible with the activity array and group them
  // so we can display how many pomodoros where finished in a day
  const groupedPomodoros = pomodoros.reduce((acc, pomodoro) => {
    const date = new Date(pomodoro).toDateString();
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(pomodoro);
    return acc;
  }, {});

  const todayPomodoroActivities = Object.entries(groupedPomodoros)
    .flatMap(([date, poms], index) => {
      const isToday = new Date(date).toDateString() === new Date().toDateString();
      if (!isToday) return []; // Skip pomodoros not from today

      return {
        id: `group-${index}`, // Ensure unique ID for the group
        type: `pomodoro-count`,
        title: `${poms.length} pomodoro${poms.length > 1 ? 's' : ''}`,
        person: { name: 'User' }, // Adjust as needed
        date: timeAgo(date), // Use the date for the group
        dateTime: date, // Use the dateTime for sorting
      };
    })
    .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));

  // Convert tasks to the correct format for the activity array
  const todaysTaskActivities = tasks
    .filter(
      (task) =>
        task.completed && new Date(task.dateCompleted).toDateString() === new Date().toDateString(),
    )
    .map((task) => ({
      id: task.id,
      type: 'task-done',
      title: task.title,
      person: { name: 'User' }, // Replace 'User' with the actual user's name if available
      date: timeAgo(task.dateCompleted),
      dateTime: task.dateCompleted,
    }));

  // task types rendered for now are pomodoros and tasks done
  const activity = [
    ...todayPomodoroActivities,
    ...todaysTaskActivities,
    /*{
      id: 1,
      type: 'created',
      person: { name: 'Chelsea Hagon' },
      date: '7d ago',
      dateTime: '2023-01-23T10:32',
    },
    {
      id: 2,
      type: 'edited',
      person: { name: 'Chelsea Hagon' },
      date: '6d ago',
      dateTime: '2023-01-23T11:03',
    },
    {
      id: 3,
      type: 'sent',
      person: { name: 'Chelsea Hagon' },
      date: '6d ago',
      dateTime: '2023-01-23T11:24',
    },
    {
      id: 4,
      type: 'commented',
      person: {
        name: 'Chelsea Hagon',
        imageUrl:
          'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      },
      comment: 'Called client, they reassured me the invoice would be paid by the 25th.',
      date: '3d ago',
      dateTime: '2023-01-23T15:56',
    },
    {
      id: 5,
      type: 'viewed',
      person: { name: 'Alex Curren' },
      date: '2d ago',
      dateTime: '2023-01-24T09:12',
    },
    {
      id: 6,
      type: 'paid',
      person: { name: 'Alex Curren' },
      date: '1d ago',
      dateTime: '2023-01-24T09:20',
    },*/
  ].sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));

  // This function will now reset the form fields and selectors when opening the create task box
  const handleShowCreateTaskBox = () => {
    setShowCreateTaskBox(true);
    //setEditingTask(null); // Reset editing task to ensure fields are empty
    setLabelled(labels[0]); // Reset label to default
    setPriority(priorities[0].value); // Reset priority to default
    setTaskStartTime('');
    setTaskEndTime('');
  };

  // Update the handleCreateTask function to also handle updates
  const handleCreateOrUpdateTask = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    let updatedTasks;

    if (editingTask) {
      // Update the task
      updatedTasks = tasks.map((task) =>
        task.id === editingTask.id
          ? {
              ...task,
              title: formData.get('title'),
              description: formData.get('description'),
              label: formData.get('label'),
              priority: formData.get('priority'),
              startTime: formData.get('startTime'),
              endTime: formData.get('endTime'),
            }
          : task,
      );
    } else {
      // Create a new task
      const newTask = {
        id: Date.now(),
        title: formData.get('title'),
        description: formData.get('description'),
        label: formData.get('label'),
        priority: formData.get('priority'),
        dateCreated: new Date().toISOString(),
        completed: false,
        startTime: formData.get('startTime'),
        endTime: formData.get('endTime'),
      };
      updatedTasks = [...tasks, newTask];
    }
    setTasks(updatedTasks);
    localStorage.setItem('tasks', JSON.stringify(updatedTasks));
    setShowCreateTaskBox(false);
    setEditSideBarOpen(false);
    setEditingTask(null); // Reset the editing task
  };

  const handleEditClick = (task) => {
    setEditingTask(task);
    setEditSideBarOpen(true);

    // Set the label and priority to the values from the task being edited
    const taskLabel = labels.find((label) => label.name === task.label) || labels[0];
    setLabelled(taskLabel);
    setPriority(task.priority);
    setTaskStartTime(task.startTime);
    setTaskEndTime(task.endTime);

    // Set the title and description in the form
    if (editTaskTitleInputRef.current) {
      editTaskTitleInputRef.current.value = task.title;
    }
    // Assuming there is a ref for the description similar to titleInputRef
    if (editDescriptionInputRef.current) {
      editDescriptionInputRef.current.value = task.description;
    }
  };

  const toggleTaskCompletion = (taskId) => {
    const updatedTasks = tasks.map((task) =>
      task.id === taskId
        ? {
            ...task,
            completed: !task.completed,
            dateCompleted: !task.completed ? new Date().toISOString() : task.dateCompleted,
          }
        : task,
    );
    setTasks(updatedTasks);
    localStorage.setItem('tasks', JSON.stringify(updatedTasks));
  };

  function removeTask(taskId) {
    const updatedTasks = tasks.filter((task) => task.id !== taskId);
    setTasks(updatedTasks);
    localStorage.setItem('tasks', JSON.stringify(updatedTasks));
  }

  // Load tasks from local storage when the component mounts
  useEffect(() => {
    const storedTasks = JSON.parse(localStorage.getItem('tasks')) || [];
    setTasks(storedTasks);
  }, []);

  // Effect to focus the title input when the form is opened for creating a new task
  useEffect(() => {
    if (showCreateTaskBox && createTaskTitleInputRef.current) {
      createTaskTitleInputRef.current.focus();
    }
  }, [showCreateTaskBox]);

  // Effect to focus the title input when the form is opened for editing a new task
  useEffect(() => {
    if (editingTask && editTaskTitleInputRef.current) {
      editTaskTitleInputRef.current.focus();
    }
  }, [editingTask]);

  useEffect(() => {
    // This function will be called every time the `events` array changes.
    const handleNewEvent = (newEvent) => {
      if (newEvent.type === 'ADD_TASK') {
        setShowCreateTaskBox(true);
        if (addTaskFormRef.current) {
          addTaskFormRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    if (events.length > 0) {
      handleNewEvent(events[events.length - 1]);
    }
  }, [events]); // The effect depends on `events`, so it will re-run when `events` changes.

  return (
    <>
      <div className='mt-8 flex justify-start text-base font-semibold text-gray-900'>
        <div>Daily Plan</div>
        <div className='flex items-center ml-2'>
          <CheckCircleIconOutlined className='h-6 w-6 text-gray-400' aria-hidden='true' />
          <span className='ml-2 text-sm text-gray-500'>
            {tasks.filter((task) => !task.completed).length} tasks left
          </span>
        </div>
      </div>
      <hr className='mt-4' />

      {/* Current Task List */}
      {tasks
        .filter((task) => !task.completed)
        .sort((a, b) => {
          // Compare by start time if both tasks have a start time set
          if (a.startTime && b.startTime) {
            const dateA = new Date(a.startTime);
            const dateB = new Date(b.startTime);

            if (dateA < dateB) return -1;
            if (dateA > dateB) return 1;
          }
          // If one of the tasks does not have a start time, sort by priority
          if (a.priority !== b.priority) {
            if (a.priority < b.priority) return -1;
            if (a.priority > b.priority) return 1;
          }
          // If priority is the same, sort by label
          if (a.label && b.label) {
            return a.label.localeCompare(b.label);
          }
          return 0;
        })
        .map((task) => (
          <div
            key={task.id}
            className={`flex items-center justify-between p-4 border-b border-gray-200 cursor-pointer`}
            onClick={() => {
              handleEditClick(task);
            }}
          >
            <div className='flex items-start'>
              <input
                className='mt-1 h-4 w-4 border-gray-500 text-indigo-600 focus:ring-indigo-600 cursor-pointer'
                type='radio'
                checked={task.completed}
                onClick={(e) => {
                  e.stopPropagation(); // prevent edit box from opening
                }}
                onChange={(e) => {
                  e.stopPropagation(); // prevent edit box from opening
                  toggleTaskCompletion(task.id);
                }}
              />
              <div className='ml-3'>
                <p className='text-sm font-medium text-gray-900'>{task.title}</p>
                <p className='text-sm text-gray-500'>{task.description}</p>
                {/* Icons for label and priority */}
                <div className='flex items-center mt-1'>
                  {task.startTime && (
                    <>
                      <ClockIcon className='h-4 w-4 text-gray-400' aria-hidden='true' />
                      {/* if end time is set and beyond the current time color the span red  */}
                      <span
                        className={`ml-1 text-xs ${
                          new Date().getTime() > new Date(task.startTime).getTime()
                            ? 'text-red-500'
                            : 'text-gray-500'
                        }`}
                      >
                        {formatTimeToHHMM(task.startTime)}
                        {task.endTime && `-${formatTimeToHHMM(task.endTime)}`}
                      </span>
                    </>
                  )}
                  <TagIcon
                    className={`h-4 w-4  ml-2 ${task.label ? 'text-gray-600' : 'text-gray-300'}`}
                    aria-hidden='true'
                  />
                  <span className='ml-1 text-xs text-gray-500'>{task.label || 'No Label'}</span>
                  <FlagIcon
                    className={`h-4 w-4 ml-2 ${
                      task.priority === '1'
                        ? 'text-red-500'
                        : task.priority === '2'
                        ? 'text-orange-500'
                        : task.priority === '3'
                        ? 'text-blue-500'
                        : 'text-gray-300'
                    }`}
                    aria-hidden='true'
                  />
                  <span className='ml-1 text-xs text-gray-500'>
                    {task.priority ? `Priority ${task.priority}` : 'No Priority'}
                  </span>
                </div>
              </div>
            </div>

            <div className='flex justify-end'>
              <div className='relative group'>
                <PlayIcon
                  className='h-5 w-5 text-gray-500 mr-2 hover:bg-gray-100 cursor-pointer'
                  aria-hidden='true'
                  onClick={(e) => {
                    e.stopPropagation();
                    /* handle play action here */
                  }}
                />
                <div className='absolute bottom-full mb-2 hidden w-auto rounded-md bg-black px-2 py-1 text-xs text-white opacity-0 group-hover:block group-hover:opacity-100'>
                  Track task
                </div>
              </div>
              <div className='relative group'>
                <PencilIcon
                  className='h-5 w-5 text-gray-500 mr-2 hover:bg-gray-100 cursor-pointer'
                  aria-hidden='true'
                  onClick={() => handleEditClick(task)}
                />

                <div className='absolute bottom-full mb-2 hidden w-auto rounded-md bg-black px-2 py-1 text-xs text-white opacity-0 group-hover:block group-hover:opacity-100 w-[65px]'>
                  Edit task
                </div>
              </div>
              <div className='relative group'>
                <TrashIcon
                  className='h-5 w-5 text-gray-500 mr-2 hover:bg-gray-100 cursor-pointer'
                  aria-hidden='true'
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent event from bubbling
                    removeTask(task.id);
                  }}
                />

                <div className='absolute bottom-full mb-2 hidden w-auto rounded-md bg-black px-2 py-1 text-xs text-white opacity-0 group-hover:block group-hover:opacity-100 w-[80px]'>
                  Delete task
                </div>
              </div>
            </div>
          </div>
        ))}

      {/* Add task inline button */}
      {!showCreateTaskBox && (
        <div className='flex flex-col space-y-2 mt-2'>
          <button
            type='button'
            className='flex items-center space-x-2 rounded-md bg-transparent p-2 text-sm font-medium text-gray-900 hover:bg-gray-100'
            onClick={handleShowCreateTaskBox}
          >
            <svg
              className='h-5 w-5'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 20 20'
              fill='currentColor'
              aria-hidden='true'
            >
              <path
                fillRule='evenodd'
                d='M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z'
                clipRule='evenodd'
              />
            </svg>
            <span>Add Task</span>
          </button>
        </div>
      )}
      {/* New task form */}
      {showCreateTaskBox && (
        <div className='mt-6 mb-4 flex gap-x-3 w-full'>
          <img
            src='https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
            alt=''
            className='h-6 w-6 flex-none rounded-full bg-gray-50'
          />
          <form
            action='#'
            className='relative flex-auto'
            onSubmit={handleCreateOrUpdateTask}
            ref={addTaskFormRef}
          >
            <div className='overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500'>
              <label htmlFor='title' className='sr-only'>
                Title
              </label>
              <input
                ref={createTaskTitleInputRef}
                type='text'
                name='title'
                id='title'
                className='block w-full border-0 pt-2.5 text-lg font-medium placeholder:text-gray-400 focus:ring-0'
                placeholder='Title'
                defaultValue=''
              />
              <label htmlFor='description' className='sr-only'>
                Description
              </label>
              <textarea
                ref={createTaskDescriptionInputRef}
                rows={2}
                name='description'
                id='description'
                className='block w-full resize-none border-0 py-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6'
                placeholder='Write a description...'
                defaultValue=''
              />

              {/* Spacer element to match the height of the toolbar */}
              <div aria-hidden='true'>
                <div className='py-2'>
                  <div className='h-9' />
                </div>
                <div className='h-px' />
                <div className='py-2'>
                  <div className='py-px'>
                    <div className='h-9' />
                  </div>
                </div>
              </div>
            </div>

            <div className='absolute inset-x-px bottom-0'>
              <div className='flex flex-nowrap justify-end space-x-2 px-2 py-2 sm:px-3'>
                {/* Task Time Listbox */}
                <Listbox as='div' className='space-y-1'>
                  {({ open }) => (
                    <>
                      <Listbox.Label className='sr-only'>Set task time</Listbox.Label>
                      <div className='relative'>
                        <Listbox.Button className='relative inline-flex items-center whitespace-nowrap rounded-full bg-gray-50 px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 sm:px-3'>
                          <ClockIcon
                            className='h-5 w-5 text-gray-500 flex-shrink-0 sm:-ml-1'
                            aria-hidden='true'
                          />
                          <span className='hidden truncate sm:ml-2 sm:block'>
                            {taskStartTime
                              ? formatTimeToHHMM(taskStartTime) +
                                '-' +
                                formatTimeToHHMM(taskEndTime)
                              : ''}
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave='transition ease-in duration-100'
                          leaveFrom='opacity-100'
                          leaveTo='opacity-0'
                        >
                          <Listbox.Options className='absolute z-10 mt-1 max-h-56 w-52 overflow-auto rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                            {/* Custom options for time selection */}
                            <div className='px-3 py-2'>
                              <label
                                htmlFor='startTime'
                                className='block text-sm font-medium text-gray-700'
                              >
                                Start Time
                              </label>
                              <input
                                type='time'
                                id='startTimeSelector'
                                name='startTimeSelector'
                                className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                value={formatTimeToHHMM(taskStartTime)}
                                onChange={(e) => {
                                  setTaskStartTime(convertTimeStringIntoAFullDate(e.target.value));
                                }}
                              />
                            </div>
                            <div className='px-3 py-2'>
                              <label
                                htmlFor='endTime'
                                className='block text-sm font-medium text-gray-700'
                              >
                                End Time
                              </label>
                              <input
                                type='time'
                                id='endTimeSelector'
                                name='endTimeSelector'
                                className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                value={formatTimeToHHMM(taskEndTime)}
                                onChange={(e) => {
                                  setTaskEndTime(convertTimeStringIntoAFullDate(e.target.value));
                                }}
                              />
                            </div>
                          </Listbox.Options>
                        </Transition>
                      </div>

                      <input type='hidden' name='startTime' value={taskStartTime} />
                      <input type='hidden' name='endTime' value={taskEndTime} />
                    </>
                  )}
                </Listbox>

                {/* Label Listbox */}
                <Listbox as='div' value={labelled} onChange={setLabelled} className='flex-shrink-0'>
                  {({ open }) => (
                    <>
                      <Listbox.Label className='sr-only'>Add a label</Listbox.Label>
                      <div className='relative'>
                        <Listbox.Button className='relative inline-flex items-center whitespace-nowrap rounded-full bg-gray-50 px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 sm:px-3'>
                          <TagIcon
                            className={classNames(
                              labelled.value === null ? 'text-gray-300' : 'text-gray-500',
                              'h-5 w-5 flex-shrink-0 sm:-ml-1',
                            )}
                            aria-hidden='true'
                          />
                          <span
                            className={classNames(
                              labelled.value === null ? '' : 'text-gray-900',
                              'hidden truncate sm:ml-2 sm:block',
                            )}
                          >
                            {labelled.value === null ? 'Label' : labelled.name}
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave='transition ease-in duration-100'
                          leaveFrom='opacity-100'
                          leaveTo='opacity-0'
                        >
                          <Listbox.Options className='absolute right-0 z-10 mt-1 max-h-56 w-52 overflow-auto rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                            {labels.map((label) => (
                              <Listbox.Option
                                key={label.value}
                                className={({ active }) =>
                                  classNames(
                                    active ? 'bg-gray-100' : 'bg-white',
                                    'relative cursor-default select-none px-3 py-2',
                                  )
                                }
                                value={label}
                              >
                                <div className='flex items-center'>
                                  <span className='block truncate font-medium'>{label.name}</span>
                                </div>
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                      <input type='hidden' name='label' value={labelled.name} />
                    </>
                  )}
                </Listbox>

                {/* New Priority Listbox */}
                <Listbox
                  as='div'
                  value={priority}
                  onChange={setPriority}
                  className='flex-shrink-0 ml-2'
                >
                  {({ open }) => (
                    <>
                      <Listbox.Label className='sr-only'>Set priority</Listbox.Label>
                      <div className='relative'>
                        <Listbox.Button className='relative inline-flex items-center whitespace-nowrap rounded-full bg-gray-50 px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 sm:px-3'>
                          <FlagIcon
                            className={classNames(
                              labelled.value === null ? 'text-gray-300' : 'text-gray-500',
                              'h-5 w-5 flex-shrink-0 sm:-ml-1',
                            )}
                            aria-hidden='true'
                          />
                          <span className='hidden truncate sm:ml-2 sm:block'>
                            {`Priority ${priority}`}
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave='transition ease-in duration-100'
                          leaveFrom='opacity-100'
                          leaveTo='opacity-0'
                        >
                          <Listbox.Options className='absolute right-0 z-10 mt-1 max-h-56 w-52 overflow-auto rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                            {priorities.map((priorityOption) => (
                              <Listbox.Option
                                key={priorityOption.value}
                                className={({ active }) =>
                                  classNames(
                                    active ? 'bg-gray-100' : 'bg-white',
                                    'relative cursor-default select-none px-3 py-2',
                                  )
                                }
                                value={priorityOption.value}
                              >
                                <div className='flex items-center'>
                                  <span className='block truncate font-medium'>
                                    {priorityOption.name}
                                  </span>
                                </div>
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                      <input type='hidden' name='priority' value={priority} />
                    </>
                  )}
                </Listbox>
              </div>
              <div className='flex items-center justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3'>
                <div className='flex'></div>
                <div className='flex-shrink-0'>
                  <button
                    type='button'
                    className='rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 mr-2'
                    onClick={() => setShowCreateTaskBox(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    className='rounded-md bg-indigo-600  px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-blue-300 hover:bg-blue-600'
                  >
                    Add Task
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
      <div className='mt-8 text-base font-semibold text-gray-900'>
        Finished Activities - {todaysTaskActivities.length} tasks, {todayPomodoroActivities.length}{' '}
        pomodoros
      </div>
      <hr className='mb-8 my-2' />
      <ul role='list' className='space-y-6'>
        {activity.map((activityItem, activityItemIdx) => (
          <li key={activityItem.id} className='relative flex gap-x-4'>
            <div
              className={classNames(
                activityItemIdx === activity.length - 1 ? 'h-6' : '-bottom-6',
                'absolute left-0 top-0 flex w-6 justify-center',
              )}
            >
              <div className='w-px bg-gray-200' />
            </div>
            {activityItem.type === 'commented' ? (
              <>
                <img
                  src={activityItem.person.imageUrl}
                  alt=''
                  className='relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50'
                />
                <div className='flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200'>
                  <div className='flex justify-between gap-x-4'>
                    <div className='py-0.5 text-xs leading-5 text-gray-500'>
                      <span className='font-medium text-gray-900'>{activityItem.person.name}</span>{' '}
                      commented
                    </div>
                    <time
                      dateTime={activityItem.dateTime}
                      className='flex-none py-0.5 text-xs leading-5 text-gray-500'
                    >
                      {activityItem.date}
                    </time>
                  </div>
                  <p className='text-sm leading-6 text-gray-500'>{activityItem.comment}</p>
                </div>
              </>
            ) : (
              <>
                <div className='relative flex h-6 w-6 flex-none items-center justify-center bg-white'>
                  {activityItem.type === 'task-done' ? (
                    <CheckCircleIcon className='h-6 w-6 text-indigo-600' aria-hidden='true' />
                  ) : (
                    <div className='h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300' />
                  )}
                </div>
                <p className='flex-auto py-0.5 ml-0.25 text-xs leading-5 text-gray-500'>
                  {activityItem.type === 'task-done' ? (
                    <span> &ldquo;{activityItem.title}&rdquo; task completed</span>
                  ) : (
                    <span> has finished {activityItem.title}</span>
                  )}
                </p>
                <time
                  dateTime={activityItem.dateTime}
                  className='flex-none py-0.5 text-xs leading-5 text-gray-500'
                >
                  {activityItem.date}
                </time>
              </>
            )}
          </li>
        ))}
      </ul>
      {/* Editing Sidebar for full-edit mode */}
      <Transition.Root show={editSideBarOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={setEditSideBarOpen}>
          <div className='fixed inset-0' />
          <div className='fixed inset-0 overflow-hidden'>
            <div className='absolute inset-0 overflow-hidden'>
              <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16'>
                <Transition.Child
                  as={Fragment}
                  enter='transform transition ease-in-out duration-500 sm:duration-700'
                  enterFrom='translate-x-full'
                  enterTo='translate-x-0'
                  leave='transform transition ease-in-out duration-500 sm:duration-700'
                  leaveFrom='translate-x-0'
                  leaveTo='translate-x-full'
                >
                  <Dialog.Panel className='pointer-events-auto w-screen max-w-md'>
                    <form
                      className='flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl'
                      onSubmit={handleCreateOrUpdateTask}
                    >
                      <div className='h-0 flex-1 overflow-y-auto'>
                        <div className='bg-indigo-700 px-4 py-6 sm:px-6'>
                          <div className='flex items-center justify-between'>
                            <Dialog.Title className='text-base font-semibold leading-6 text-white'>
                              Edit Task
                            </Dialog.Title>
                            <div className='ml-3 flex h-7 items-center'>
                              <button
                                type='button'
                                className='relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white'
                                onClick={() => setEditSideBarOpen(false)}
                              >
                                <span className='absolute -inset-2.5' />
                                <span className='sr-only'>Close panel</span>
                                <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className='flex flex-1 flex-col justify-between'>
                          <div className='divide-y divide-gray-200 px-4 sm:px-6'>
                            <div className='space-y-6 pb-5 pt-6'>
                              <div>
                                <label
                                  htmlFor='title'
                                  className='block text-sm font-medium leading-6 text-gray-900'
                                >
                                  Title
                                </label>
                                <div className='mt-2'>
                                  <input
                                    ref={editTaskTitleInputRef}
                                    type='text'
                                    name='title'
                                    id='title'
                                    className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                    placeholder='Title'
                                    defaultValue={editingTask ? editingTask.title : ''}
                                  />
                                </div>
                              </div>
                              {/* Description input */}
                              <div>
                                <label
                                  htmlFor='description'
                                  className='block text-sm font-medium leading-6 text-gray-900'
                                >
                                  Description
                                </label>
                                <div className='mt-2'>
                                  <textarea
                                    ref={editDescriptionInputRef}
                                    rows={4}
                                    name='description'
                                    id='description'
                                    className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                    placeholder='Write a description...'
                                    defaultValue={editingTask ? editingTask.description : ''}
                                  />
                                </div>
                              </div>
                              {/* Task Time Listbox */}
                              <Listbox as='div' className='space-y-1'>
                                {({ open }) => (
                                  <>
                                    <Listbox.Label className='sr-only'>Set task time</Listbox.Label>
                                    <div className='relative'>
                                      <Listbox.Button className='relative inline-flex items-center whitespace-nowrap rounded-full bg-gray-50 px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 sm:px-3'>
                                        <ClockIcon
                                          className='h-5 w-5 text-gray-500 flex-shrink-0 sm:-ml-1'
                                          aria-hidden='true'
                                        />
                                        <span className='hidden truncate sm:ml-2 sm:block'>
                                          {editingTask && (taskStartTime || taskEndTime)
                                            ? `${
                                                taskStartTime
                                                  ? formatTimeToHHMM(taskStartTime)
                                                  : formatTimeToHHMM(editingTask.startTime)
                                              }-${
                                                taskEndTime
                                                  ? formatTimeToHHMM(taskEndTime)
                                                  : formatTimeToHHMM(editingTask.endTime)
                                              }`
                                            : 'Set time'}
                                        </span>
                                      </Listbox.Button>

                                      <Transition
                                        show={open}
                                        as={Fragment}
                                        leave='transition ease-in duration-100'
                                        leaveFrom='opacity-100'
                                        leaveTo='opacity-0'
                                      >
                                        <Listbox.Options className='absolute z-10 mt-1 max-h-56 w-52 overflow-auto rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                                          {/* Custom options for time selection */}
                                          <div className='px-3 py-2'>
                                            <label
                                              htmlFor='startTime'
                                              className='block text-sm font-medium text-gray-700'
                                            >
                                              Start Time
                                            </label>
                                            <input
                                              type='time'
                                              id='startTimeSelector'
                                              name='startTimeSelector'
                                              defaultValue={
                                                editingTask &&
                                                (taskStartTime || editingTask.startTime)
                                                  ? formatTimeToHHMM(
                                                      taskStartTime || editingTask.startTime,
                                                    )
                                                  : ''
                                              }
                                              className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                              //value={formatTimeToHHMM(taskStartTime)}
                                              onChange={(e) => {
                                                setTaskStartTime(
                                                  convertTimeStringIntoAFullDate(e.target.value),
                                                );
                                              }}
                                            />
                                          </div>
                                          <div className='px-3 py-2'>
                                            <label
                                              htmlFor='endTime'
                                              className='block text-sm font-medium text-gray-700'
                                            >
                                              End Time
                                            </label>
                                            <input
                                              type='time'
                                              id='endTimeSelector'
                                              name='endTimeSelector'
                                              defaultValue={
                                                editingTask && (taskEndTime || editingTask.endTime)
                                                  ? formatTimeToHHMM(
                                                      taskEndTime || editingTask.endTime,
                                                    )
                                                  : ''
                                              }
                                              className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                              //value={formatTimeToHHMM(taskEndTime)}
                                              onChange={(e) => {
                                                setTaskEndTime(
                                                  convertTimeStringIntoAFullDate(e.target.value),
                                                );
                                              }}
                                            />
                                          </div>
                                        </Listbox.Options>
                                      </Transition>
                                    </div>

                                    <input type='hidden' name='startTime' value={taskStartTime} />
                                    <input type='hidden' name='endTime' value={taskEndTime} />
                                  </>
                                )}
                              </Listbox>
                              {/* Label Listbox */}
                              <Listbox
                                as='div'
                                value={labelled}
                                onChange={setLabelled}
                                className='flex-shrink-0'
                              >
                                {({ open }) => (
                                  <>
                                    <Listbox.Label className='sr-only'>Add a label</Listbox.Label>
                                    <div className='relative'>
                                      <Listbox.Button className='relative inline-flex items-center whitespace-nowrap rounded-full bg-gray-50 px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 sm:px-3'>
                                        <TagIcon
                                          className={classNames(
                                            labelled.value === null
                                              ? 'text-gray-300'
                                              : 'text-gray-500',
                                            'h-5 w-5 flex-shrink-0 sm:-ml-1',
                                          )}
                                          aria-hidden='true'
                                        />
                                        <span
                                          className={classNames(
                                            labelled.value === null ? '' : 'text-gray-900',
                                            'hidden truncate sm:ml-2 sm:block',
                                          )}
                                        >
                                          {labelled.value === null ? 'Label' : labelled.name}
                                        </span>
                                      </Listbox.Button>

                                      <Transition
                                        show={open}
                                        as={Fragment}
                                        leave='transition ease-in duration-100'
                                        leaveFrom='opacity-100'
                                        leaveTo='opacity-0'
                                      >
                                        <Listbox.Options className='absolute right-0 z-10 mt-1 max-h-56 w-52 overflow-auto rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                                          {labels.map((label) => (
                                            <Listbox.Option
                                              key={label.value}
                                              className={({ active }) =>
                                                classNames(
                                                  active ? 'bg-gray-100' : 'bg-white',
                                                  'relative cursor-default select-none px-3 py-2',
                                                )
                                              }
                                              value={label}
                                            >
                                              <div className='flex items-center'>
                                                <span className='block truncate font-medium'>
                                                  {label.name}
                                                </span>
                                              </div>
                                            </Listbox.Option>
                                          ))}
                                        </Listbox.Options>
                                      </Transition>
                                    </div>
                                    <input type='hidden' name='label' value={labelled.name} />
                                  </>
                                )}
                              </Listbox>

                              {/* New Priority Listbox */}
                              <Listbox
                                as='div'
                                value={priority}
                                onChange={setPriority}
                                className='flex-shrink-0 ml-2'
                              >
                                {({ open }) => (
                                  <>
                                    <Listbox.Label className='sr-only'>Set priority</Listbox.Label>
                                    <div className='relative'>
                                      <Listbox.Button className='relative inline-flex items-center whitespace-nowrap rounded-full bg-gray-50 px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 sm:px-3'>
                                        <FlagIcon
                                          className={classNames(
                                            labelled.value === null
                                              ? 'text-gray-300'
                                              : 'text-gray-500',
                                            'h-5 w-5 flex-shrink-0 sm:-ml-1',
                                          )}
                                          aria-hidden='true'
                                        />
                                        <span className='hidden truncate sm:ml-2 sm:block'>
                                          {`Priority ${priority}`}
                                        </span>
                                      </Listbox.Button>

                                      <Transition
                                        show={open}
                                        as={Fragment}
                                        leave='transition ease-in duration-100'
                                        leaveFrom='opacity-100'
                                        leaveTo='opacity-0'
                                      >
                                        <Listbox.Options className='absolute right-0 z-10 mt-1 max-h-56 w-52 overflow-auto rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                                          {priorities.map((priorityOption) => (
                                            <Listbox.Option
                                              key={priorityOption.value}
                                              className={({ active }) =>
                                                classNames(
                                                  active ? 'bg-gray-100' : 'bg-white',
                                                  'relative cursor-default select-none px-3 py-2',
                                                )
                                              }
                                              value={priorityOption.value}
                                            >
                                              <div className='flex items-center'>
                                                <span className='block truncate font-medium'>
                                                  {priorityOption.name}
                                                </span>
                                              </div>
                                            </Listbox.Option>
                                          ))}
                                        </Listbox.Options>
                                      </Transition>
                                    </div>
                                    <input type='hidden' name='priority' value={priority} />
                                  </>
                                )}
                              </Listbox>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex flex-shrink-0 justify-end px-4 py-4'>
                        <button
                          type='button'
                          className='rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                          onClick={() => setEditSideBarOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type='submit'
                          className='ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                        >
                          {'Update Task'}
                        </button>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
